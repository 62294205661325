<template>
    <section>
      <TransferenciaLista />
    </section>
  </template>
  <script>
  import TransferenciaLista from "@/components/transferencia/transferenciaLista.vue"
  import { defineComponent } from "vue";
  
  export default defineComponent({
    components: {
        TransferenciaLista
    },
    data () {
      return {
  
      }
    },
    async beforeMount() {
  
    },
    methods:{
    },
  })
  </script>
  
  
  <style lang="scss" scoped>
    .table-example--pagination {
      padding-top: 20px;
      text-align: center;
      text-align: -webkit-center;
    }
  </style>
  
  <style>
   .th_prf{
      background-color: #0067a7 !important;
      color: #fff;
      text-align: left;
      padding: 15px !important;
      padding-left: 25px !important;
      border: 1px solid;
   }
   .th_prf1{
      background-color:  #2e92d1 !important;
      color: #fff;
      text-align: left;
      padding: 15px !important;
      padding-left: 25px !important;
      border: 1px solid;
   }
   .tbody_prf{
      border-top: 0px solid currentColor !important;
   }
  </style>
  